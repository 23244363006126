import httpManager from "../axios/httpManager";
import {AxiosInstance as axios} from "axios";


export function getSportsBetInfoList(search, pageNum, pageSize, orderBy) {
    let url = '/sports/bet/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}

export function updateSportsBetInfo(betInfo) {
    return httpManager.post('/sports/bet/update', betInfo);
}

export function updateSportsBetInfoResultCancel(betInfo) {
    return httpManager.post('/sports/bet/result_cancel', betInfo);
}

export function getBetInfoStatisticByAttribute(search) {
    return httpManager.post('/sports/bet/get_statistic_by_attribute', search);
}

//add by azy start
export function listSportsBetInfoByGameId(id, val) {
    return httpManager.get('/sports/bet/listBetInfoByGameId?gameId=' + id + "&selectedVal=" + val + '&t=' + new Date().getTime());
}

export function getStatisticByAttribute(betInfo) {
    return httpManager.post('/sports/bet/getStatisticByAttribute', betInfo);
}

export function initSportsBetInfo(id) {
    return httpManager.get('/sports/bet/init_bet_info?id=' + id + '&t=' + new Date().getTime());
}

export function downLoadBetInfo(betInfo) {
    return httpManager.download('/sports/bet/download_betlist?t=' + new Date().getTime(),betInfo)
}